import axios from '@/api/axios'
import { CategoryModel } from './models/CategoryModel'

class CategoryService {
  async getCategories(): Promise<CategoryModel[]> {
    try {
      const response = await axios.get('api/b2b/v1/categories')
      return response.data.data
    } catch (error) {
      console.log(error)
      return error.response.data
    }
  }
}

export default new CategoryService()
